import Button, { ButtonVariants } from '@/components/atoms/Button/Button';
import { GOOGLE_TRACK_INFO } from '@/lib/constants';
import { EnvelopeIcon } from '@heroicons/react/24/solid';
import router from 'next/router';

const analytics = {
  login: GOOGLE_TRACK_INFO.emailLoginButton,
  signup: GOOGLE_TRACK_INFO.emailSignUpButton,
};

interface EmailFederationButtonProps {
  actionType: 'login' | 'signup';
  continueText?: boolean;
  sendAnalytics?: boolean;
  preHook?: () => void;
}

const EmailFederationButton: React.FC<EmailFederationButtonProps> = ({
  actionType,
  continueText,
  sendAnalytics,
  preHook,
}) => {
  let actionText = '';
  if (continueText) {
    actionText = 'Continue';
  } else if (actionType === 'login') {
    actionText = 'Login';
  } else if (actionType === 'signup') {
    actionText = 'Sign up';
  }

  const content = `${actionText} with email`;

  return (
    <Button
      icon={{
        icon: <EnvelopeIcon className="size-xl" />,
        position: 'left',
      }}
      aria-label={content}
      onClick={() => {
        preHook?.();
        if (actionType === 'signup') {
          sessionStorage.setItem('isSigningUpToSendMessage', 'true');
          router.push('/sign-up/email');
        } else {
          router.push('/login/email');
        }
      }}
      variant={ButtonVariants.Secondary}
      analyticsEvent={sendAnalytics ? analytics[actionType] : undefined}
    >
      {content}
    </Button>
  );
};

export default EmailFederationButton;
