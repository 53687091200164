import Head from 'next/head';

export type BlogType = Partial<{
  title: string;
  description: string;
  imgSrc: string;
  type: string;
  canonical: string;
  url: string;
  keywords?: string;
}>;
export const SocialSharingTags = (props: BlogType) => {
  const {
    title = 'Electric Cars for Sale',
    imgSrc,
    description = 'Buy New & Pre-Owned Electric Vehicles.',
    canonical,
    url,
    keywords,
  } = props;
  const domain =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3000'
      : `https://${process.env.NEXT_PUBLIC_DOMAIN}`;

  const lowercaseDomain = domain ? domain.toLowerCase() : '';

  const contentImage = lowercaseDomain?.includes('ev.com')
    ? `${domain}/images/og/ev_com_main.png`
    : `${domain}/images/og/ev_ca_main.png`;

  return (
    <Head>
      {/* basic tags */}
      <title key="title">{title}</title>
      {canonical && <link rel="canonical" href={canonical} />}
      <meta name="description" content={description} />

      {/* open graph tags */}
      {(imgSrc || domain) && (
        <meta
          property="og:image"
          content={imgSrc || contentImage}
          key="og-image"
        />
      )}

      <meta
        property="og:title"
        content={title || 'Electric Cars for Sale'}
        key="og-title"
      />

      <meta
        property="og:description"
        content={description}
        key="og-description"
      />

      {keywords && <meta name="keywords" content={keywords} key="keywords" />}

      {url && <meta property="og:url" content={url} key="og-url" />}

      {/* twitter tags */}
      <meta name="twitter:title" content={title} key="twitter-title" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@EVcom" />
      <meta name="twitter:creator" content="@EVcom" />
      <meta
        name="twitter:description"
        content={description}
        key="twitter-description"
      />
      {(imgSrc || domain) && (
        <meta
          name="twitter:image"
          content={imgSrc || contentImage}
          key="twitter-image"
        />
      )}
    </Head>
  );
};
