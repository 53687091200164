import Button, { ButtonVariants } from '@/components/atoms/Button/Button';
import Icon, { IconIds } from '@/components/atoms/legacy/Icon';
import { GOOGLE_TRACK_INFO } from '@/lib/constants';
import { AnalyticsEvent } from '@/types';
import { Auth, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { FC } from 'react';

export type IdentityProvider = 'facebook' | 'google' | 'apple' | 'microsoft';

const ButtonConfigMap: Record<
  IdentityProvider,
  {
    name: string;
    signIn: () => Promise<void>;
    iconId: IconIds;
    analytics: { login: AnalyticsEvent; signup: AnalyticsEvent };
    strokeColor?: string;
    fillColor?: string;
  }
> = {
  google: {
    iconId: IconIds.Google,
    analytics: {
      login: GOOGLE_TRACK_INFO.googleLoginButton,
      signup: GOOGLE_TRACK_INFO.googleSignUpButton,
    },
    signIn: async () => {
      await Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google,
      });
    },
    name: 'Google',
  },
  facebook: {
    iconId: IconIds.Facebook,
    analytics: {
      login: GOOGLE_TRACK_INFO.facebookLoginButton,
      signup: GOOGLE_TRACK_INFO.facebookSignUpButton,
    },
    signIn: async () => {
      await Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Facebook,
      });
    },
    name: 'Facebook',
  },
  apple: {
    iconId: IconIds.BlackApple,
    analytics: {
      login: GOOGLE_TRACK_INFO.appleLoginButton,
      signup: GOOGLE_TRACK_INFO.appleSignUpButton,
    },
    signIn: async () => {
      await Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Apple,
      });
    },
    name: 'Apple',
  },
  microsoft: {
    iconId: IconIds.Microsoft,
    analytics: {
      login: GOOGLE_TRACK_INFO.microsoftPersonalLoginButton,
      signup: GOOGLE_TRACK_INFO.microsoftPersonalSignUpButton,
    },
    signIn: async () => {
      await Auth.federatedSignIn({
        customProvider: 'Microsoft',
      });
    },
    name: 'Microsoft',
  },
};

export interface SocialFederationButtonProps {
  identityProvider: IdentityProvider;
  actionType: 'login' | 'signup';
  sendAnalytics?: boolean;
  preHook?: () => void;
  onSuccess?: () => void;
  onFailure?: () => void;
}

export const SocialFederationButton: FC<SocialFederationButtonProps> = ({
  identityProvider,
  actionType,
  sendAnalytics,
  preHook,
  onSuccess,
  onFailure,
}) => {
  const {
    name,
    iconId,
    signIn,
    analytics,
    strokeColor: customStrokeColor,
    fillColor: customFillColor,
  } = ButtonConfigMap[identityProvider];

  const content = `Continue with ${name}`;

  return (
    <Button
      icon={
        iconId
          ? {
              icon: (
                <Icon
                  iconId={iconId}
                  className="size-xl"
                  fillColor={customFillColor}
                  strokeColor={customStrokeColor}
                />
              ),
              position: 'left',
            }
          : undefined
      }
      aria-label={content}
      onClick={() => {
        preHook?.();
        signIn().then(onSuccess).catch(onFailure);
      }}
      variant={ButtonVariants.Secondary}
      analyticsEvent={sendAnalytics ? analytics[actionType] : undefined}
    >
      {content}
    </Button>
  );
};
