import React, { PropsWithChildren } from 'react';
import { BaseButtonProps } from '@/types';
import { useAnalytics } from '@/components/molecules/AnalyticsProvider';

export enum TextButtonVariants {
  Default = 'Default',
  Secondary = 'Secondary',
  Destructive = 'Destructive',
}

export interface TextButtonProps extends BaseButtonProps {
  variant?: TextButtonVariants;
  size?: 'small' | 'medium';
}

const TextButton: React.FC<PropsWithChildren<TextButtonProps>> = ({
  children,
  icon,
  analyticsEvent,
  onClick = () => {},
  size = 'medium',
  variant = TextButtonVariants.Default,
  ...props
}) => {
  const { handleActionTracking } = useAnalytics();

  let wrapperClassName = `transition-colors duration-500 group relative flex flex-grow inline-flex text-body2Regular ${size === 'small' ? 'px-[0px]' : 'px-xs'} py-s cursor-default`;

  if (props.disabled) {
    wrapperClassName = `${wrapperClassName} text-neutral-400`;
  } else {
    wrapperClassName = `${wrapperClassName} text-blue-medium hover:text-brandMidnight-hover cursor-pointer`;
  }
  if (variant === TextButtonVariants.Destructive) {
    wrapperClassName = `${wrapperClassName} text-red-dark`;
    if (props.disabled) {
      wrapperClassName = `${wrapperClassName} opacity-[.3]`;
    } else {
      wrapperClassName = `${wrapperClassName} hover:text-red-medium `;
    }
  } else if (variant === TextButtonVariants.Secondary) {
    wrapperClassName = `${wrapperClassName} text-neutral-0`;
    if (props.disabled) {
      wrapperClassName = `${wrapperClassName} opacity-[.3]`;
    } else {
      wrapperClassName = `${wrapperClassName} hover:text-neutral-200 `;
    }
  }

  return (
    <button
      className={wrapperClassName}
      onClick={(e) => {
        if (analyticsEvent) {
          handleActionTracking(analyticsEvent);
        }
        onClick(e);
      }}
      {...props}
    >
      <div className="flex items-center justify-center gap-[6px] whitespace-nowrap">
        {icon && icon.position === 'left' && icon.icon}
        {children}
        {icon && icon.position !== 'left' && icon.icon}
      </div>
    </button>
  );
};

export default TextButton;
