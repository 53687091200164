import { XMarkIcon } from '@heroicons/react/24/solid';
import * as RadixDialog from '@radix-ui/react-dialog';
import { FC, PropsWithChildren, ReactNode } from 'react';

export interface DialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  /** The React node to render for opening the modal, like a button */
  renderTrigger?: ReactNode;
  contentClassName?: string;
}

export const Dialog: FC<PropsWithChildren<DialogProps>> = ({
  children,
  renderTrigger,
  open,
  onOpenChange,
  contentClassName = '',
}) => (
  <RadixDialog.Root open={open} onOpenChange={onOpenChange}>
    {renderTrigger && (
      <RadixDialog.Trigger asChild>{renderTrigger}</RadixDialog.Trigger>
    )}
    <RadixDialog.Portal>
      <RadixDialog.Overlay className="b-transparent fixed inset-0 z-40 bg-black/50 data-[state=open]:animate-overlayShow" />
      <RadixDialog.Content
        className={`fixed left-[50%] top-[50%] z-50 max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none data-[state=open]:animate-contentShow ${contentClassName}`}
      >
        {children}
        <RadixDialog.Close
          asChild
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onOpenChange(false);
          }}
        >
          <button
            aria-label="Close"
            className="absolute right-[10px] top-[10px]"
          >
            <XMarkIcon height={24} width={24} />
          </button>
        </RadixDialog.Close>
      </RadixDialog.Content>
    </RadixDialog.Portal>
  </RadixDialog.Root>
);
